import React, { useState, useEffect } from "react";
import Lottie from 'lottie-react';
import { PopupButton } from "react-calendly";

import dog from './dog2.json';
// import paw from './paw.svg';
import './styles.scss';


function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
}
  
function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener("resize", handleResize);
	  return () => window.removeEventListener("resize", handleResize);
	}, []);
  
	return windowDimensions;
}



const Home = () => {
	const { height, width } = useWindowDimensions();
	const style = {
		width: width*0.9,
		height: height*0.4
	};

	return (
		<div className="App">
			<div className="txtTitle">
				<h1>Veterinaria<br/>San Cristóbal</h1>
			</div>
			<div className="mainContainer">
				{/* <img src={paw} alt='' className="paw" /> */}
				<div className="animation">
					<Lottie
						animationData={dog}
						style={style}
						className="test"
					/>
					{/* <Lottie 
						options={defaultOptions}
						height={400}
						width={400}
					/> */}
				</div>
				<div>
					<PopupButton
						url="https://go.oncehub.com/VeterinariaSanCristbal"
						rootElement={document.getElementById("root")}
						text="Agendar cita"
						className="button"
					/>
				</div>
			</div>

		</div>
	);
};
	
	export default Home;